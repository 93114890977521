import { Navbar } from '../../components/navbar/Navbar';
import { ActuSection } from '../../components/actuSection/ActuSection';
import { ProductSection } from '../../components/productSection/ProductSection';
import { AvantageSection } from '../../components/avantagesSection/AvantageSection';
import { ProjectSection } from '../../components/projectSection/ProjectSection';
import { Footer } from '../../components/footer/Footer';

export function Home() {
  return (
    <div>
      <Navbar></Navbar>
      <ActuSection></ActuSection>
      <ProductSection></ProductSection>
      <AvantageSection></AvantageSection>
      <ProjectSection></ProjectSection>
      <Footer></Footer>
    </div>
  );
}
