export function Footer() {
  return (
    <div className={'w-full bg-black flex justify-center gap-20 pt-5 pb-5 flex-wrap'}>
      <a className={'text-white'} href={'#'}>
        Mention légales
      </a>
      <a className={'text-white'} href={'#'}>
        Tous droits réservés
      </a>
      <a className={'text-white'} href={'#'}>
        Données personnelles
      </a>
    </div>
  );
}
