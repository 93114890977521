export type ProductItemProps = {
  name: string;
  image: string;
};

export function ProductItem({ name, image }: ProductItemProps) {
  return (
    <div className="w-52 h-72 bg-white rounded-xl shadow-xl z-10">
      <a href="#" className={''}>
        <img className="rounded-t-xl h-4/5 object-cover" src={image} alt="" />
      </a>
      <div className="pt-4">
        <a href="#">
          <p className="text-center font-bold">{name}</p>
        </a>
      </div>
    </div>
  );
}
