import { useState } from 'react';
import { NavbarItem } from './NavbarItem';

export function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 xl:mb-20">
      <div className="container flex flex-wrap w-full md:flex-nowrap items-center justify-between mx-auto">
        <a href="#" className="flex items-center">
          <img src="/assets/images/logo.png" className="h-6 mr-3 sm:h-9 " alt="SupDev Logo" />
        </a>
        <div className="flex flex-grow justify-end md:order-2">
          <button
            type="button"
            className="shadow-xl px-1 py-1 flex mr-3 text-sm bg-black rounded-full justify-end md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            id="user-menu-button"
            aria-expanded="false"
            data-dropdown-toggle="user-dropdown"
            data-dropdown-placement="bottom"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              className="w-6 h-6 p-1"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`${
            !isMobileMenuOpen && 'hidden'
          } w-full md:block md:w-auto" id="navbar-default`}
        >
          <ul className="flex justify-end flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <NavbarItem href={'#'} name={'Accueil'}></NavbarItem>
            <NavbarItem href={'https://docs.supdev.tech/'} name={'Documentation'}></NavbarItem>
            <NavbarItem href={'https://discord.gg/SkS6ZUr3RN'} name={'Discord'}></NavbarItem>
          </ul>
        </div>
      </div>
    </nav>
  );}


  
/*
          <button
            type="button"
            className="shadow-xl text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-1 mr-2 w-32 "
          >
            Se connecter 
          </button>
            <NavbarItem name={'Pôles'}></NavbarItem>
            <NavbarItem name={'Equipe'}></NavbarItem>
            <NavbarItem name={"S'inscrire"}></NavbarItem>
*/
