import React from 'react';
import { Home } from './pages/home/Home';

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
