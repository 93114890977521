export function ActuSection() {
  return (
    <div className={'mt-8 max-w-screen-xl mx-auto'}>
      <div className="mx-0 sm:mx-20 bg-black pb-10 xl:h-96 sm:rounded-xl flex flex-col xl:flex-row shadow-xl">
        <div className={'p-10 flex flex-col xl:w-1/2 '}>
          <h1 className={'text-white font-bold text-2xl'}>
            Réaliser un projet n'a jamais été aussi simple !
          </h1>
          <p className={'pt-5 text-white pb-10'}>
            Depuis 2021, Sup'Dev propose initialement des services de développement informatique
            autour du jeu Minecraft : plugins, mods, bots discord et sites web. Le projet s'est agrandi
            et propose des services de graphisme et de build de maps. L'objectif de Sup'Dev est de vous 
            permettre de réaliser vos projets de A à Z chez nous.
          </p>
          <a className={'bg-white py-1 rounded-3xl font-bold text-center'} href={'https://discord.com/invite/SkS6ZUr3RN'}>Découvrez nos services</a>
        </div>
        <div className={'mx-auto xl:w-1/2 xl:relative xl:-top-6 xl:ml-28 '}>
          <img
            src="/assets/images/homepage-image1.jpg"
            alt={'image-homepage1'}
            className={'w-110 h-110 rounded-2xl overflow-hidden shadow-xl object-cover'}
          />
          <img
            src="/assets/images/homepage-image2.jpg"
            alt={'image-homepage1'}
            className={
              'w-40 h-40 relative -top-10 mx-auto xl:-top-36 xl:-left-20 xl:mx-0 rounded-2xl overflow-hidden shadow-xl object-cover'
            }
          />
        </div>
      </div>
    </div>
  );
}
