export function ProjectSection() {
  return (
    <>
      <h1 className={'text-center text-3xl font-bold'}>Nos réalisations</h1>
      <div className={'flex justify-center gap-20 mb-20 flex-wrap'}>
        <div>
          <img
            src={
              'https://tiermaker.com/images/templates/tier-list-rle-aot-uhc--golden-1113886/11138861625774932.png'
            }
            alt={'goldenLogo'}
            className={'h-60'}
          />
          <p className="text-center font-bold">GoldenUHC</p>
        </div>
        <div>
          <img
            src={
              'https://media.discordapp.net/attachments/880580016516264006/1023696965587980379/borutoZKO_Vape_commande.png?ex=652c4f97&is=6519da97&hm=ce85bd4995e7908df81718de164d10f49ef35abe48f578dac70ec8ac82167e07&=&width=676&height=676'
            }
            alt={'goldenLogo'}
            className={'h-60'}
          />
          <p className="text-center font-bold">BorutoUHC</p>
        </div>
        <div>
          <img
            src={
              'https://media.discordapp.net/attachments/955054888272949259/1160488657938034738/Zaretix2.png?ex=6534d848&is=65226348&hm=9e0f05cde14608b056f101c33cffa658153ad66b74a94ab17445d4265c44c8b2&=&width=655&height=655'
            }
            alt={'goldenLogo'}
            className={'h-60'}
          />
          <p className="text-center font-bold">Zaretix V2</p>
        </div>
      </div>
    </>
  );
}
