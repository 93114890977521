type NavbarItemProps = {
  name: string;
  href: string;
};

export function NavbarItem({ name, href }: NavbarItemProps) {
  return (
    <li>
      <a href={href} className="block py-2 pl-3 pr-4 md:bg-transparent md:text-black-700 md:p-0">
        {name}
      </a>
    </li>
  );
}
