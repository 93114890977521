import { AvantageItem } from './AvantageItem';

export const Products = [
  {
    name: 'Mise a jour auto',
    description: 'Nos plugins se mettent à jour automatiquement.',
    image: '/assets/images/avantageLogo/realtime.svg',
  },
  {
    name: 'Support',
    description: 'Notre engagement est de vous offrir la meilleure expérience en  fournissant un support rapide et efficace.',
    image: '/assets/images/avantageLogo/repair-fix-repairing.svg',
  },
  {
    name: 'Configuration',
    description: 'Avec nos plugins, chaque détail est personnalisable pour répondre parfaitement à vos besoins.',
    image: '/assets/images/avantageLogo/gears.svg',
  },
  {
    name: 'Licence',
    description: 'Grâce à une licence dédiée, vous avez un contrôle total sur vos plugins, assurant la sécurité de vos projets.',
    image: '/assets/images/avantageLogo/personal-details.svg',
  },
];

export function AvantageSection() {
  return (
    <>
      <h1 className={'text-center text-3xl font-bold'}>Nos avantages</h1>
      <div className={'h-60 w-60 bg-black absolute left-0 z-0 rounded-r-2xl'}></div>
      <div className={'flex pt-10 pb-40 flex-wrap gap-10 justify-center '}>
        {Products.map((product) => {
          return (
            <AvantageItem
              name={product.name}
              description={product.description}
              image={product.image}
            />
          );
        })}
      </div>
    </>
  );
}
