import { ProductItem } from './ProductItem';

export const Products = [
  {
    name: 'Plugins minecraft',
    image: '/assets/images/dev-minecraft.jpg',
  },
  {
    name: 'Graphisme',
    image: '/assets/images/graphisme.jpg',
  },
  {
    name: 'Bots discord',
    image: '/assets/images/bot-discord.jpg',
  },
  {
    name: 'Builds minecraft',
    image: '/assets/images/Build_minecraft.jfif',
  },
  {
    name: 'Site web',
    image: '/assets/images/site-web.jpg',
  },
];

export function ProductSection() {
  return (
    <>
      <h1 className={'pt-28 text-center text-3xl font-bold'}>Ce qu'on fait pour vous</h1>
      <div className={'h-60 w-60 bg-black absolute right-0 z-0 rounded-l-2xl'}></div>
      <div className={'flex pt-10 pb-24 flex-wrap gap-5 justify-center '}>
        {Products.map((product) => {
          return <ProductItem name={product.name} image={product.image} />;
        })}
      </div>
    </>
  );
}
