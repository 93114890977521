export type ProductItemProps = {
  name: string;
  description: string;
  image: string;
};

export function AvantageItem({ name, description, image }: ProductItemProps) {
  return (
    <div className="w-52 h-72 bg-white rounded-xl shadow-xl z-10">
      <a href="#" className={''}>
        <img className="rounded-t-xlobject-cover w-20 mx-auto h-1/2" src={image} alt="" />
      </a>
      <div className="pt-4">
        <a href="#">
          <h1 className="text-center font-bold">{name}</h1>
          <p className="text-center">{description}</p>
        </a>
      </div>
    </div>
  );
}
